const explanations = {
  wh: {
    title: "Power Consumption per day (Watt hour)",
    content: "A Watt Hour is a unit of measurement for power over a period of time (an hour), " +
      "or in our case, a way of measuring capacity. One Watt hour is equal to one Watt of " +
      "average power flow over an hour. " +
      "One Watt over four hours would be four Watt Hours of power. If we only use  LED of 10W for 5hours a day, our final Wh would be 10x5 = 50Wh",
    video: "cxkVxi9P0EA",
  },
  daysbackup: {
    title: "Number of day of backup",
    content: "Decide how many days of autonomy you want your solar system to be able to provide. The longer, the bigger you battery bank will be.",
  },
  mdd: {
    title: "Battery type",
    content: "Lead-acid batteries cost less up front, but they have a shorter lifespan and should only be run to 50% depth of discharge. Lithium batteries are much more expensive, but they have a longer lifespan and can handle deep discharges of 100%.",
    video: "SID-f7XgpYk",
  },
  voltage: {
    title: "Voltage",
    content: "Select which voltage you are planning to support. Usually, 12V, 24V or 48V are the most used.",
  }
};



const batteryList = {
  // Depth discharge 50%
  50: {
    // Voltage battery 12V
    12: {
      // AmpH battery 100
      100: {
        img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07SW353M8&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
        link: "https://amzn.to/3hHBnab"
      },
    },
  },
  // Depth discharge 100%
  100: {
    // Voltage battery 12V
    12: {
      // AmpH battery 100
      100: {
        img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
        link: "https://amzn.to/2VVbA6I"
      },
      // AmpH battery 200
      200: {
        img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
        link: "https://amzn.to/3lsZfPC"
      },
    },
    // Voltage battery 24V
    24: {
      // AmpH battery 100
      100: {
        img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6LP1J9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
        link: "https://amzn.to/3hEsRsk"
      },
    },
    // Voltage battery 48V
    48: {
      // AmpH battery 100
      100: {
        img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08RSLP1PQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
        link: "https://amzn.to/3zjfUua"
      },
    },
  },
};

export {
  explanations,
  batteryList
};
