import {useState} from "react";
import {Formik} from "formik";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {AiFillCalculator, BsTrash} from "react-icons/all";

import ModalInfo from "../ModalInfo";
import {explanations} from "../global";
import Result from "../Result";

import "./Calculator.css";

const Calculator = () => {
  const [batteryBankResult, setBatteryBankResult] = useState();
  const handleSubmitForm = ({
    wh,
    daybackup,
    mdd,
    voltage,
  }) => {
    var A = parseFloat(wh);
    var B = parseFloat(daybackup);
    var C = parseFloat(mdd);
    var D = parseFloat(voltage);
    const ampH = Math.round((A * B / (C / 100)) / D);
    console.log("SUBMIT", ampH);
    setBatteryBankResult(ampH);
  };

  return (
    <Container>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{
          wh: 0,
          daybackup: 1,
          mdd: 100,
          voltage: 12
        }}
      >
        {({
            handleSubmit,
            handleChange,
            handleReset,
            values,
          }) => (
          <Form
            className="form-horizontal"
            onSubmit={handleSubmit}
            role="form"
          >
            <Row className="g-2">
              <Form.Group as={Col} controlId="wh">
                <Form.Label>
                  Power consumption per day (Wh):
                  <ModalInfo
                    title={explanations.wh.title}
                    content={explanations.wh.content}
                    video={explanations.wh.video}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={values.wh}
                  placeholder="Enter the power consumption per day in Wh."
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="daybackup">
                <Form.Label>
                  Days backup required:
                  <ModalInfo
                    title={explanations.daysbackup.title}
                    content={explanations.daysbackup.content}
                    video={explanations.daysbackup.video}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={values.daybackup}
                  placeholder="Enter number of days of backup."
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className='g-2'>
              <Form.Group as={Col} controlId="mdd">
                <Form.Label>
                  Battery type:
                  <ModalInfo
                    title={explanations.mdd.title}
                    content={explanations.mdd.content}
                    video={explanations.mdd.video}
                  />
                </Form.Label>
                <Form.Select
                  aria-label="mdd"
                  value={values.mdd}
                  onChange={handleChange}
                >
                  <option value="50">Lead acid Battery - 50% Max depth of discharge</option>
                  <option value="100">Lithium iron phosphate Battery - 100% Max depth of discharge</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="voltage">
                <Form.Label>
                  Voltage:
                  <ModalInfo
                    title={explanations.voltage.title}
                    content={explanations.voltage.content}
                  />
                </Form.Label>
                <Form.Select
                  aria-label="voltage"
                  value={values.voltage}
                  onChange={handleChange}
                >
                  <option value="12">12V</option>
                  <option value="24">24V</option>
                  <option value="48">48V</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="g-2 buttons-calc">
              <Col>
                <Button
                  type="submit"
                  variant="warning"
                  color="white"
                >
                  <AiFillCalculator />
                  {" Calculate"}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setBatteryBankResult();
                    handleReset();
                  }}
                >
                  <BsTrash color="white"/>
                  {" Reset"}
                </Button>
              </Col>
            </Row>

            <Result
              ampH={batteryBankResult}
              voltage={values.voltage}
              discharge={values.mdd}
            />

          </Form>
        )}

      </Formik>
    </Container>
  )
};

export default Calculator;
