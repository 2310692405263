import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";

import "./Result.css";

import { batteryList } from "../global";

const txtLow = "Your power consumption should be too low. Please update it";

const Result = ({ampH = "", voltage = 12, discharge = 100}) => {
  const [batteriesList, setBatteryList] = useState([]);

  useEffect(() => {
    const batteryAmpH = batteryList[discharge][voltage];
    const options = [];
    Object.keys(batteryAmpH).forEach((ampB) => {
      const nbrBattery = Math.ceil(ampH / ampB);
      options.push({
        name: `Battery ${voltage}V${ampB}Ah`,
        count: nbrBattery,
        img: batteryAmpH[ampB].img,
        src: batteryAmpH[ampB].link
      });
    });
    setBatteryList(options);
  }, [ampH, voltage, discharge]);

  if (typeof ampH !== "number") {
    return null;
  }

  return (
    <Row className="g-1 App-result">
      <Col>
        <div className="results">
          <h4>Minimum battery bank size:</h4>
          <div className="line-results">
            {
              ampH < 1
                ? <span className="App-result-low">{txtLow}</span>
                : <b>{ampH}Ah</b>
            }

          </div>

        </div>
      </Col>

      {
        ampH >= 1
          ? (
            <Form.Group>
              <div className="col-sm-12">
                <p>
                  <b>Note</b>:
                  <br/>
                  When possible, we can recommend amazon products base on your results.
                </p>
                <div>
                  <table>
                    <tbody>
                    {
                      batteriesList.map((batt, i) => (
                        <tr key={i}>
                          <td><b>{`${batt.count}x ${batt.name}`}</b></td>
                          <td>
                            This battery on amazon
                            <a href={batt.src} target="_blank" rel="noreferrer">
                              <img alt={`Amazon battery ${batt.name}`} src={batt.img} />
                            </a>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </Form.Group>
          )
          : null
      }

    </Row>
  )
};

export default Result;
