import "./Disclaimer.css";

import {Accordion, Card, Container, useAccordionButton} from "react-bootstrap";

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <div className="App-disclaimer-other" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const Disclaimer = () => (
  <div className="App-disclaimer">
      <Container>
        <div className="App-disclaimer-content">
          <Accordion className="App-disclaimer-accordion">
            <CustomToggle eventKey="0">Click to see other solar calculators</CustomToggle>

              <Accordion.Collapse eventKey="0">
                <Card>
                <Card.Body>
                  Here is a list of other solar calculators you can use.
                  <br />
                  <a href="https://solarwiresizecalculator.com">www.solarwiresizecalculator.com</a>
                  <br/>
                  <a href="https://www.solarpowersizingcalculator.com/">www.solarpowersizingcalculator.com</a>
                </Card.Body>
                </Card>
              </Accordion.Collapse>
          </Accordion>
          <div className="App-disclaimer-separator"/>
          Copyright  Solar Battery Bank Calculator© 2021
          <br />
          www.solarbatterybankcalculator.com is a participant in the Amazon Services LLC Associates Program,
          an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com
          We make a commission for sales made through affiliate links posted on this website.
        </div>
      </Container>
  </div>
);

export default Disclaimer;
