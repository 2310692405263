import {Accordion, Container} from "react-bootstrap";
import logo from "./images/logo2.png";

const TopContainer = () => (
  <Container className="App-why">
    <img src={logo} className="App-why-logo" alt="Solar Wire Size Calculator"/>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Calculate your battery bank for your solar system</Accordion.Header>
        <Accordion.Body className="why-body">
          Calculate the minimum recommended battery bank size in amp-hours (Ah).
          <br />
          Calculation is based on the power consumption of the system, voltage, battery type and desired
          length of backup power required.
          <br/>
          Enter the daily power consumption in Watt per hour (Wh) and check the data.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Container>
);

export default TopContainer;
