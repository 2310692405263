import Disclaimer from "./Disclaimer";

import "./App.css";

import Calculator from "./Calculator";
import TopContainer from "./TopContainer";

function App() {


  return (
    <div className="App">
      <TopContainer />

      <Calculator />

      <Disclaimer />
    </div>
  );
}

export default App;
